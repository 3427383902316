import styled from "styled-components";
import { dimensions } from "../../../values";
import colors from "../../../values/colors";

export const Container = styled.div`
  min-height: 100vh;
  width: 100%;
  position: relative;
  display: grid;

  background: ${colors.BACKGROUND_MEDIUM};
  grid-template-columns: ${dimensions.SIDEBAR_WIDTH} 1fr;
  grid-template-rows: ${dimensions.HEADER_HEIGHT} 1fr;
`;

export const BarContainer = styled.div`
  position: absolute;
`;

export const ContentContainer = styled.div`
  min-height: calc(100vh - ${dimensions.HEADER_HEIGHT});
  width: calc(100vw - ${dimensions.SIDEBAR_WIDTH});

  background-color: ${colors.BACKGROUND_DARK};
`;

export const FlexContainer = styled.div`
  display: flex;
`;
