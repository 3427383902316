import styled from "styled-components";

export const Background = styled.img`
  width: 100%;
`;
export const Overlay = styled.img`
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
`;
export const NiyonAvatar = styled.img`
  width: 40%;
  position: absolute;
  top: -7.5%;
  left: -5%;
`;
export const LimitedEdition = styled.img`
  width: 40%;
  position: absolute;
  top: 10%;
  left: 35%;
`;
export const NFTSale = styled.img`
  width: 40%;
  position: absolute;
  top: 25%;
  left: 45%;
`;
export const BuyNow = styled.div`
  width: 20%;
  height: 25%;
  position: absolute;
  bottom: 10%;
  right: 5%;
  background-image: url("images/promotions/niyon/button_buyNow.webp");
  background-repeat: no-repeat;
  background-size: 100%;

  &:hover {
    background-image: url("images/promotions/niyon/button_buyNow_over.webp");
  }
  &:active {
    background-image: url("images/promotions/niyon/button_buyNow_clicked.webp");
  }
`;
