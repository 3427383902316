import React from "react";
import {
  Container,
  SocialsBoxContainer,
  SocialsContainer,
  Subtitle,
  TitleContainer,
} from "./index.styles";

const Footer = () => {
  return (
    <Container>
      <TitleContainer>
        <img src="images/logos/defverse_full.webp" alt="facebook_icon" />
      </TitleContainer>
      <SocialsContainer>
        <SocialsBoxContainer>
          <img src="images/icons/facebook.webp" alt="facebook_icon" />
        </SocialsBoxContainer>
        <SocialsBoxContainer>
          <img src="images/icons/twitter.webp" alt="twitter_icon" />
        </SocialsBoxContainer>
        <SocialsBoxContainer>
          <img src="images/icons/discord.webp" alt="discord_icon" />
        </SocialsBoxContainer>
        <SocialsBoxContainer>
          <img src="images/icons/instagram.webp" alt="instagram_icon" />
        </SocialsBoxContainer>
        <SocialsBoxContainer>
          <img src="images/icons/reddit.webp" alt="reddit_icon" />
        </SocialsBoxContainer>
      </SocialsContainer>
      <Subtitle>
        <span>Copyright © DefVerse 2021. All rights reserved</span>
        <span>Terms of use | Privacy policy</span>
      </Subtitle>
    </Container>
  );
};

export default Footer;
