import React from "react";
import { AdContainer } from "../../common";
import { Container } from "./index.styles";

const BalloonGuild = () => {
  return (
    <AdContainer>
      <Container>
        <img src="images/ads/balloon_guild/scholarship.webp" alt="ads" />
        <img src="images/ads/balloon_guild/guild.webp" alt="ads" />
      </Container>
    </AdContainer>
  );
};

export default BalloonGuild;
