import React from "react";
import {
  Container,
  LinkIconContainer,
  LinkText,
  SideBarLinkContainer,
} from "./index.styles";

const Sidebar = () => {
  return (
    <Container>
      <SideBarLinkContainer>
        <LinkIconContainer></LinkIconContainer>
        <LinkText>NFT</LinkText>
      </SideBarLinkContainer>
      <SideBarLinkContainer>
        <LinkIconContainer></LinkIconContainer>
        <LinkText>STAKING</LinkText>
      </SideBarLinkContainer>
      <SideBarLinkContainer>
        <LinkIconContainer></LinkIconContainer>
        <LinkText>AUCTIONS</LinkText>
      </SideBarLinkContainer>
      <SideBarLinkContainer>
        <LinkIconContainer></LinkIconContainer>
        <LinkText>EVENTS</LinkText>
      </SideBarLinkContainer>
      <SideBarLinkContainer>
        <LinkIconContainer></LinkIconContainer>
        <LinkText>LINK E</LinkText>
      </SideBarLinkContainer>
      <SideBarLinkContainer>
        <LinkIconContainer></LinkIconContainer>
        <LinkText>SCHOLARSHIPS</LinkText>
      </SideBarLinkContainer>
      <SideBarLinkContainer>
        <LinkIconContainer></LinkIconContainer>
        <LinkText>CONTACT US</LinkText>
      </SideBarLinkContainer>
    </Container>
  );
};

export default Sidebar;
