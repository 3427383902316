import styled from "styled-components";
import { backgrounds, colors } from "../../../values";

export type HoverableProps = {
  hovered: boolean;
};
export const Container = styled.div`
  & > div {
    margin: 0.5rem 1.5rem;
  }
  position: relative;
  min-height: 7rem;
  display: flex;
  flex-direction: column;
  gap: 0;
`;
export const Content = styled.div<HoverableProps>`
  padding: 1rem 1rem 0.5rem 1rem;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  gap: 1rem;

  border-radius: 20px;
  background: ${colors.ITEM_BACKGROUND};
  background: ${backgrounds.ITEM_GRADIENT};
  position: relative;

  color: ${colors.FONT_LIGHT};
  min-height: 7rem;

  background-image: ${(props) =>
    !props.hovered
      ? `url("images/auctionbox.webp")`
      : `url("images/auctionbox_over.webp")`};
  background-repeat: no-repeat;
  background-size: 100% 100%;
`;
export const ContentHeader = styled.div`
  width: 100%;
  font-weight: bold;
  margin-left: 1rem;
`;
export const BidButton = styled.div<HoverableProps & { clicked?: boolean }>`
  padding: 0.5rem 0.5rem;
  position: absolute;

  border-radius: 10px;
  background: ${colors.PURPLE};
  font-size: 0.75rem;
  font-weight: bold;
  bottom: 0.5rem;
  right: 0;
  margin: 0 1rem 1rem 0;

  background-image: ${(props) =>
    props.clicked
      ? `url("images/multi_clicked.webp")`
      : !props.hovered
      ? `url("images/multi.webp")`
      : `url("images/multi_over.webp")`};
  background-repeat: no-repeat;
  background-size: 100% 100%;
  cursor: pointer;
`;

export const CostContainer = styled.div<HoverableProps>`
  background: rgb(39, 44, 63);
  background: linear-gradient(
    360deg,
    rgba(39, 44, 63, 1) 37%,
    rgba(57, 60, 69, 1) 74%,
    rgba(94, 94, 96, 1) 100%
  );
  border-radius: 20px;
  padding: 0.75rem 1rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  height: 2rem;
  color: ${colors.FONT_LIGHT};
  justify-content: space-between;

  background-image: ${(props) =>
    !props.hovered
      ? `url("images/auctionbox_header.webp")`
      : `url("images/auctionbox_header_over.webp")`};
  background-repeat: no-repeat;
  background-size: 100% 100%;
`;
export const IconCostContainer = styled.div`
  width: 2rem;
  height: 100%;

  & > img {
    width: 100%;
  }
`;
export const CostSpan = styled.span`
  font-weight: bold;
`;
export const MultiplierSpan = styled.span``;
export const AvatarContainer = styled.div`
  width: 70%;
  position: absolute;
  top: -2rem;
  right: -20%;

  & > img {
    width: 100%;
  }
  & > :last-child {
    width: 50%;
    position: relative;
    left: 17.5%;
    top: -1rem;
  }
`;
export const Subtitle = styled.span`
  position: absolute;
  top: -0.5rem;
  left: 0;
  font-size: 0.75rem;
  color: #abaea4;
  width: 100%;
  text-align: center;
`;
export const StatsContainer = styled.div`
  width: 55%;
  display: flex;
  flex-direction: column;
  min-height: 7rem;
`;
export const StatsItem = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 0.5rem;
  & > :first-child {
    font-weight: bold;
  }
`;
export const RemainingSpan = styled.span`
  font-size: 0.5rem;
  color: #abaea4;
  text-align: center;
`;
