import styled from "styled-components";
import colors from "../../../values/colors";

export const Container = styled.div`
  position: relative;
  min-height: 30rem;

  border: solid 2px ${colors.BORDER_MEDIUM};
  background-color: ${colors.AD_CONTENT_BACKGROUND};
  border-radius: 1rem;
`;
