import styled from "styled-components";
import { dimensions } from "../../../values";
import colors from "../../../values/colors";

export const Container = styled.div`
  position: fixed;
  height: calc(100vh - ${dimensions.HEADER_HEIGHT});
  width: ${dimensions.SIDEBAR_WIDTH};
  z-index: 100;

  position: sticky;
  top: ${dimensions.HEADER_HEIGHT};

  display: flex;
  flex-direction: column;
  gap: 1rem;

  background: ${colors.BACKGROUND_MEDIUM};

  overflow-y: scroll;

  ::-webkit-scrollbar {
    width: 0px;
  }

  @media (max-width: 768px) {
    height: 100vh;
  }
`;

export const SideBarLinkContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  align-items: center;
  padding-top: 1rem;
`;
export const LinkIconContainer = styled.div`
  background-color: lightgray;
  width: 3rem;
  height: 3rem;
`;
export const LinkText = styled.span`
  color: ${colors.FONT_LIGHT};
  font-size: 0.5rem;
`;
