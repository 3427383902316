import styled from "styled-components";

export const Container = styled.div`
  padding: 2.5rem;
`;
export const Content = styled.div`
  position: relative;
`;
export const ActionContainer = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  padding: 1rem;

  border: solid thin yellow;
`;
