import styled from "styled-components";
import { backgrounds, colors } from "../../../values";

type ContainerProps = {
  colorOnHover?: string;
};

export const Container = styled.div<ContainerProps>`
  & > div {
    margin: 0.5rem 1.5rem;
  }
  position: relative;
  min-height: 7rem;
  display: flex;
  flex-direction: column;
  gap: 0;
  cursor: pointer;

  &:hover {
    & > :not(:first-child) {
      box-shadow: ${(props) =>
        props.colorOnHover && `0 0 5px 5px ${props.colorOnHover}`};
    }
  }
`;
export const Content = styled.div`
  padding: 0.5rem;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;

  border-radius: 20px;
  background: ${colors.ITEM_BACKGROUND};
  background: ${backgrounds.ITEM_GRADIENT};

  color: ${colors.FONT_LIGHT};
`;
export const ContentHeader = styled.div`
  text-align: center;
  width: 100%;
  font-weight: bold;
  font-size: 0.75rem;
  margin-top: 1rem;
`;
export const ContentDetails = styled.div`
  text-align: center;
  width: 100%;
  font-size: 0.75rem;
  flex-grow: 1;
`;
export const ContentActionContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 1rem;
  width: 100%;
  justify-content: space-around;
`;

type ContentActionProps = {
  isBought?: boolean;
};

export const ContentAction = styled.div<ContentActionProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 0.75;
  font-size: 0.5rem;
  font-weight: bold;
  text-align: center;
  border-radius: 10px;
  background: ${(props) =>
    props.isBought ? colors.BACKGROUND_MEDIUM : colors.PURPLE};
`;
export const ContentMultiplier = styled.div`
  width: 1rem;
  padding: 0.5rem 0.5rem;

  border-radius: 10px;
  background: ${colors.PURPLE};
  font-size: 0.75rem;
  text-align: center;
`;

export const CostContainer = styled.div`
  background: rgb(39, 44, 63);
  background: linear-gradient(
    360deg,
    rgba(39, 44, 63, 1) 37%,
    rgba(57, 60, 69, 1) 74%,
    rgba(94, 94, 96, 1) 100%
  );
  border-radius: 20px;
  padding: 0.75rem 1rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  height: 2rem;
  color: ${colors.FONT_LIGHT};
`;
export const IconCostContainer = styled.div`
  width: 2rem;
  height: 100%;

  & > img {
    width: 100%;
  }
`;
export const CostSpan = styled.span`
  font-weight: bold;
`;
export const MultiplierSpan = styled.span``;
export const AvatarContainer = styled.div`
  width: 4rem;
  height: 6rem;
  border: solid thin green;
  position: absolute;
  top: -0.5rem;
  right: -1rem;
`;
