import React from "react";
import { Container, Content } from "./index.styles";

type BannerProps = {
  children?: React.ReactNode;
};

const Banner = (props: BannerProps) => {
  return (
    <Container>
      <Content>{props.children}</Content>
    </Container>
  );
};

export default Banner;
