const colors = {
  BACKGROUND_MEDIUM: "#222632",
  BACKGROUND_DARK: "#11141c",
  BORDER_MEDIUM: "#2d313d",
  AD_CONTENT_BACKGROUND: "#1f222f",
  FONT_LIGHT: "#e6eaec",
  ITEM_BACKGROUND: "rgb(39, 44, 63)",
  PURPLE: "#435cff",
  YELLOW_HOVER: "#c7dd04",
  GREEN_HOVER: "#7eb012",
  RED_HOVER: "#991012",
};

export default colors;
