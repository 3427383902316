import styled from "styled-components";
import colors from "../../../values/colors";

export const Container = styled.div`
  width: 100%;
  position: relative;
  display: flex;
`;

export const ContentContainer = styled.div`
  flex-grow: 1;
  position: relative;
  order: 1;
  min-height: calc(100vh - 5rem);
  width: calc(100% - max(20rem, 20%));
`;
export const AdsContainer = styled.div`
  width: max(15rem, 20%);
  position: relative;
  order: 2;

  padding: 1rem calc(0.5rem + 5px) 0.5rem 0.5rem;

  & > * {
    margin-bottom: 1rem;
  }

  @media (max-width: 768px) {
    display: none;
  }

  background-color: ${colors.BACKGROUND_MEDIUM};
`;
