import styled from "styled-components";
import colors from "../../../values/colors";

export const Container = styled.div`
  height: 30rem;
  padding: 1rem;
  border: solid 2px ${colors.BORDER_MEDIUM};
  background-color: ${colors.AD_CONTENT_BACKGROUND};
  border-radius: 1rem;
  overflow-y: scroll;

  ::-webkit-scrollbar {
    width: 0px;
  }
`;
export const Header = styled.div`
  border-bottom: solid 2px ${colors.BORDER_MEDIUM};
  padding-left: 1rem;
  padding-right: 1rem;
  padding-bottom: 0.5rem;
  margin-bottom: 1rem;
  color: ${colors.FONT_LIGHT};
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;

  &::after {
    content: "";
    position: absolute;
    width: 0.5rem;
    height: 0.5rem;
    background-color: green;
    border-radius: 50%;
    top: calc(50% - 0.5rem);
    left: 0.25rem;
  }
`;
export const EditButton = styled.span`
  font-size: 0.75rem;
  color: "#aaaaaa";
  cursor: pointer;
  &:hover {
    color: #efefef;
  }
  &:active {
    color: #4efffc;
  }
`;

export const ChatContainer = styled.div`
  height: 5rem;
  margin-top: 0.5rem;
  padding-bottom: 0.5rem;
  position: relative;
  display: flex;
  border-radius: 10px;

  background-color: ${colors.BACKGROUND_MEDIUM};
`;
export const ChatContentContainer = styled.div`
  margin-top: 0.5rem;
  padding: 0.5rem;
  padding-left: 2rem;
  display: flex;
  flex-direction: column;
  color: ${colors.FONT_LIGHT};
  flex-grow: 1;
`;
export const Sender = styled.div`
  font-weight: 600;
  font-size: 0.9rem;
  margin-left: 0.5rem;
  margin-bottom: 0.5rem;
`;
export const Content = styled.div`
  font-size: 0.75rem;
  text-overflow: ellipsis;
  overflow: hidden;
`;
export const ChatAvatarContainer = styled.div`
  position: absolute;
  top: -0.5rem;
  left: -0.5rem;
  width: 3rem;
  height: 3rem;
  & > img {
    width: 100%;
  }
`;
export const SeeAll = styled.div`
  padding-top: 0.5rem;
  color: ${colors.FONT_LIGHT};
  text-align: center;
  font-size: 0.75rem;
  cursor: pointer;
  &:hover {
    color: yellow;
  }
`;
