import styled from "styled-components";
import { colors } from "../../../values";

export const Container = styled.div`
  padding: 0 2.5rem 2.5rem 2.5rem;
  position: relative;
  margin: 2rem 0 1rem 0;
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
`;
export const Content = styled.div`
  background-color: ${colors.AD_CONTENT_BACKGROUND};
  display: flex;
  flex-wrap: wrap;
  padding: 1rem;
  width: 100%;
  top: -2px;
  position: relative;

  & > div {
    width: 25%;
    margin-bottom: 1rem;

    @media (max-width: 1440px) {
      width: calc(100% / 3);
    }

    @media (max-width: 1240px) {
      width: 50%;
    }

    @media (max-width: 960px) {
      width: 100%;
    }
  }

  border: solid 2px ${colors.BORDER_MEDIUM};
  border-radius: 5rem;

  @media (max-width: 840px) {
    border-radius: 3rem;
  }
  @media (max-width: 480px) {
    border-radius: 1rem;
  }
`;

export const Header = styled.div`
  background-color: ${colors.AD_CONTENT_BACKGROUND};
  border: solid thin red;
  width: 50%;
  padding: 1rem 1rem;
  border: solid 2px ${colors.BORDER_MEDIUM};
  border-radius: 1rem 1rem 0 0;
  border-bottom: none;
  font-size: 2rem;
  color: ${colors.FONT_LIGHT};
  font-weight: bold;
  text-align: center;
  top: -4.45rem;
  left: 25%;
  z-index: 10;

  @media (max-width: 960px) {
    width: 65%;
  }
`;
