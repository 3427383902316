import React, { useState } from "react";
import { Container, FooterContainer, HeaderContainer } from "./index.styles";

export type GalleryImageProps = {
  src: string;
  containerOffsetY?: string;
};

const GalleryImage = (props: GalleryImageProps) => {
  const { src, containerOffsetY = "0" } = props;

  return (
    <Container top={containerOffsetY}>
      <GalleryAvatar src={src} />
      <HeaderContainer>
        <img src="images/logos/currency.webp" alt="logo" />
        <span>150.00</span>
      </HeaderContainer>
      <FooterContainer>
        <FooterGalleryButton src="buynow" />
        <MultiplierButton />
      </FooterContainer>
    </Container>
  );
};
const FooterGalleryButton = (props: { src: string }) => {
  const [source, setSource] = useState(`images/${props.src}`);
  return (
    <img
      src={`${source}.webp`}
      alt="hello"
      onMouseOver={() => {
        setSource(`images/${props.src}_over`);
      }}
      onMouseOut={() => {
        setSource(`images/${props.src}`);
      }}
      onMouseDown={() => {
        setSource(`images/${props.src}_clicked`);
      }}
      onMouseUp={() => {
        setSource(`images/${props.src}`);
      }}
    />
  );
};

const GalleryAvatar = (props: { src: string }) => {
  const { src } = props;
  const [source, setSource] = useState(`images/gallery/${src}`);
  return (
    <img
      src={`${source}.webp`}
      alt="hello"
      onMouseOver={() => {
        setSource((prev) => `${prev}_over`);
      }}
      onMouseOut={() => {
        setSource(`images/gallery/${src}`);
      }}
    />
  );
};
const MultiplierButton = () => {
  return (
    <div>
      <FooterGalleryButton src="multi" />
      <div>
        <span>&times;3</span>
      </div>
    </div>
  );
};

export default GalleryImage;
