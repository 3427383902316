import React from "react";
import { Container } from "./index.styles";

type AdContainerProps = {
  children?: React.ReactNode;
};

const AdContainer = (props: AdContainerProps) => {
  return <Container>{props.children}</Container>;
};

export default AdContainer;
