import React from "react";
import { Banner } from "../../common";
import {
  Background,
  BuyNow,
  LimitedEdition,
  NFTSale,
  NiyonAvatar,
  Overlay,
} from "./index.styles";

const NiyonSale = () => {
  return (
    <Banner>
      <Background
        src="images/promotions/niyon/shader_featurePanel.webp"
        alt="bg"
      />
      <Overlay src="images/promotions/niyon/image_featurePanel.webp" alt="bg" />
      <NiyonAvatar src="images/promotions/niyon/image_niyon.webp" alt="bg" />
      <LimitedEdition
        src="images/promotions/niyon/text_LimitedEdition.webp"
        alt="bg"
      />
      <NFTSale src="images/promotions/niyon/text_NFTSale.webp" alt="bg" />
      <BuyNow></BuyNow>
    </Banner>
  );
};

export default NiyonSale;
