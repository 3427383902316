import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 20px 10px;

  & > img {
    width: calc(100% - 20px);
  }
  & > :first-child {
    width: 100%;
    position: relative;
    left: -20px;
  }
`;
