import React, { useState } from "react";
import {
  AccountAvatarContainer,
  AccountBalance,
  AccountBalanceContainer,
  AccountBalanceIcon,
  AccountBalanceText,
  AccountContainer,
  AvatarContainer,
  Container,
  DefVerseContainer,
  DownButton,
  PlusImage,
  SpaceGuardiansContainer,
} from "./index.styles";

const Header = () => {
  return (
    <Container>
      <DefVerseContainer>
        <img src="images/logos/defverse.webp" alt="logo" />
      </DefVerseContainer>
      <SpaceGuardiansContainer>
        <img src="images/logos/space_guardians.webp" alt="logo" />
      </SpaceGuardiansContainer>
      <AccountContainer>
        <AccountBalanceContainer>
          <AccountBalance>
            <AccountBalanceIcon>
              <img src="images/logos/currency.webp" alt="logo" />
            </AccountBalanceIcon>
            <AccountBalanceText>1,234,546</AccountBalanceText>
            <AddBalanceButton />
          </AccountBalance>
        </AccountBalanceContainer>
        <AccountAvatarContainer>
          <AvatarContainer>
            <img src="images/icons/account_avatar.webp" alt="logo" />
          </AvatarContainer>
          <DownButton>&#62;</DownButton>
        </AccountAvatarContainer>
      </AccountContainer>
    </Container>
  );
};

const AddBalanceButton = () => {
  const [source, setSource] = useState(`images/icons/addbutton`);
  return (
    <PlusImage
      src={`${source}.webp`}
      alt="hello"
      onMouseOver={() => {
        setSource(`images/icons/addbutton_over`);
      }}
      onMouseOut={() => {
        setSource(`images/icons/addbutton`);
      }}
      onMouseDown={() => {
        setSource(`images/icons/addbutton_clicked`);
      }}
      onMouseUp={() => {
        setSource(`images/icons/addbutton`);
      }}
    />
  );
};

export default Header;
