import React from "react";
import { AdsContainer, Container, ContentContainer } from "./index.styles";

type LayoutProps = {
  children?: React.ReactNode;
};

const Ads = ({ children }: LayoutProps) => {
  return <AdsContainer>{children}</AdsContainer>;
};
const Content = ({ children }: LayoutProps) => {
  return <ContentContainer>{children}</ContentContainer>;
};

const PageWithAds = ({ children }: LayoutProps) => {
  return <Container>{children}</Container>;
};

PageWithAds.Ads = Ads;
PageWithAds.Content = Content;

export default PageWithAds;
