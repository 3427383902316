import React from "react";
import AuctionItem from "../AuctionItem";
import { Container, Content, Header } from "./index.styles";

const AuctionHouse = () => {
  return (
    <Container>
      <Content>
        <AuctionItem />
        <AuctionItem />
        <AuctionItem />
        <AuctionItem />
        <AuctionItem />
      </Content>
      <Header>AUCTION HOUSE</Header>
    </Container>
  );
};

export default AuctionHouse;
