import styled from "styled-components";

export type GalleryContainerProps = {
  top: string;
};

export const Container = styled.div<GalleryContainerProps>`
  position: relative;

  & > div {
    position: absolute;
  }
  & > img {
    width: 100%;
    position: relative;
    top: ${(props) => props.top};

    @media (max-width: 1024px) {
      top: ${(props) => `calc(${props.top} * 0.7)`};
    }
  }
`;

export const HeaderContainer = styled.div`
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  gap: 2.5%;
  padding: 0 10%;
  margin-top: 11.75%;
  align-items: center;

  & > img {
    width: 12.5%;
    aspect-ratio: 1;
  }
  & > span {
    font-size: 0.75rem;
    color: white;
    font-weight: bold;

    @media (max-width: 480px) {
      font-size: 0.75rem;
    }
  }
`;

export const FooterContainer = styled.div`
  bottom: 12.5%;
  left: 0;
  width: 100%;
  display: flex;
  gap: 14%;
  padding: 0 10%;
  align-items: center;
  & > img {
    width: 45%;
    height: auto !important;
    cursor: pointer;
  }
  & > div {
    width: 10%;
    position: relative;
    display: flex;
    align-items: center;
    cursor: pointer;

    & > img {
      width: 100%;
      height: auto !important;
    }
    & > div {
      pointer-events: none;
      position: absolute;
      top: 0;
      left: 0;
      color: white;
      font-size: 60%;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
`;
