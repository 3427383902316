import styled from "styled-components";
import { dimensions } from "../../../values";
import colors from "../../../values/colors";

export const Container = styled.div`
  position: sticky;
  height: calc(${dimensions.HEADER_HEIGHT} - 0.5rem);
  width: 100vw;
  z-index: 101;

  top: 0;

  grid-column: span 2;
  display: flex;
  justify-content: space-between;

  background-color: ${colors.BACKGROUND_MEDIUM};
  padding-top: 0.5rem;
`;

export const SpaceGuardiansContainer = styled.div`
  height: 100%;
  width: 10rem;
  position: absolute;
  left: calc(50% - 5rem);

  & > img {
    width: 100%;
  }
`;
export const DefVerseContainer = styled.div`
  position: relative;
  height: 100%;
  width: ${dimensions.SIDEBAR_WIDTH};
  display: grid;
  place-items: center;

  & > img {
    width: 50%;
  }
`;

export const AccountContainer = styled.div`
  height: 100%;
  display: flex;
  gap: 2rem;
  padding-right: 1rem;
`;
export const AccountBalanceContainer = styled.div`
  display: flex;
  align-items: center;
`;
export const AccountBalance = styled.div`
  border: solid 1px ${colors.FONT_LIGHT};
  border-radius: 10px;
  align-items: center;
  display: flex;
  gap: 1rem;
  padding: 0.5rem 1rem 0.5rem 0.5rem;
  min-width: 6rem;
  position: relative;
`;
export const AccountBalanceIcon = styled.div`
  width: 1rem;
  height: 1rem;

  & > img {
    width: 100%;
  }
`;
export const AccountBalanceText = styled.span`
  font-size: 0.75rem;
  color: ${colors.FONT_LIGHT};
`;
export const PlusIcon = styled.div`
  position: absolute;
  width: 1.5rem;
  height: 1.5rem;
  background-color: ${colors.PURPLE};
  color: ${colors.FONT_LIGHT};
  font-weight: bold;
  display: flex;
  justify-content: center;
  border-radius: 5px;
  right: -0.75rem;
  cursor: pointer;

  &:hover {
    color: yellow;
  }
`;
export const PlusImage = styled.img`
  position: absolute;
  width: 1.5rem;
  height: 1.5rem;
  right: -0.75rem;
  cursor: pointer;
`;
export const AccountAvatarContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;
export const AvatarContainer = styled.div`
  width: 2rem;
  height: 2rem;
  & > img {
    width: 100%;
  }
`;
export const DownButton = styled.span`
  transform: rotate(90deg);
  color: ${colors.FONT_LIGHT};
  font-weight: bold;

  cursor: pointer;
  &:hover {
    color: yellow;
  }
`;
