import styled from "styled-components";
import colors from "../../../values/colors";

export const Container = styled.div`
  background-color: ${colors.BACKGROUND_DARK};
`;

export const Gallery = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 2rem 1rem 0rem 1rem;
  position: relative;
  padding-bottom: 0rem;

  & > div {
    width: 25%;
    margin-bottom: 1rem;

    @media (max-width: 1024px) {
      width: calc(100% / 3);
    }

    @media (max-width: 840px) {
      width: 50%;
    }

    @media (max-width: 480px) {
      width: 100%;
    }
  }
`;
export const GalleryPagingContainer = styled.div`
  position: absolute;
  height: 3rem;
  bottom: 0;
  left: 0;
  width: 100% !important;
  display: flex;
  gap: 1rem;
  justify-content: center;
`;
export const PagingItem = styled.span`
  color: ${colors.FONT_LIGHT};
  font-weight: bold;
  font-size: 1.25rem;

  display: flex;
  align-items: center;

  cursor: pointer;
`;
