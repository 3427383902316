import React from "react";
import Footer from "../Footer";
import Header from "../Header";
import Sidebar from "../Sidebar";
import { Container, ContentContainer } from "./index.styles";

type LayoutProps = {
  children: JSX.Element;
};

const Layout = ({ children }: LayoutProps) => {
  return (
    <>
      <Container>
        <Header />
        <Sidebar />
        <ContentContainer>{children}</ContentContainer>
      </Container>
      <Footer />
    </>
  );
};

export default Layout;
