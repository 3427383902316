import styled from "styled-components";
import colors from "../../../values/colors";

export const Container = styled.div`
  height: 20rem;
  width: 100vw;
  position: relative;
  z-index: 200;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;

  background-color: ${colors.BACKGROUND_MEDIUM};
`;
export const TitleContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem 0;
  min-height: 5rem;

  & > img {
    height: 4rem;
  }
`;
export const SocialsContainer = styled.div`
  display: flex;
  gap: 1rem;
  justify-content: center;
`;
export const SocialsBoxContainer = styled.div`
  width: 3.5rem;
  height: 3.5rem;

  @media (max-width: 480px) {
    width: 3rem;
    height: 3rem;
  }

  & > img {
    width: 100%;
  }

  cursor: pointer;
`;
export const Subtitle = styled.div`
  color: ${colors.FONT_LIGHT};
  display: flex;
  gap: 0.5rem;
  flex-direction: column;

  & > * {
    text-align: center;
    font-size: 0.75rem;
  }
`;
