import React from "react";
import { BalloonGuild } from "../../ads";
import { AuctionHouse, ChatBox } from "../../common";
import GalleryImage from "../../common/GalleryImage";
import { PageWithAds } from "../../layouts";
import { NiyonSale } from "../../promotions";
import { Gallery } from "./index.styles";

/*const items: Array<ItemProps> = [
  {
    colorOnHover: colors.YELLOW_HOVER,
    cost: 150,
    header: "Founder's Box",
    details: "Unlock one Random Founders Edition NFT",
    multiplier: 3,
  },
  {
    colorOnHover: colors.GREEN_HOVER,
    cost: 50,
    header: "Play For Rewards",
    details: "Unlock one Random Platform NFT",
    multiplier: 3,
  },
  {
    colorOnHover: colors.RED_HOVER,
    cost: 30,
    header: "Mystery Box",
    details:
      "Unlock one Random NFT including Platform, Weapons, Utility + Ships",
    multiplier: 3,
  },
  {
    cost: 10,
    header: "Materials Cache",
    details: "Cache of materials with a chance to discover Upgrade Crystals",
    multiplier: 3,
    isBought: true,
  },
];

const gallery: GalleryImageProps[] = [
  {
    src: "founders",
  },
  {
    src: "platform",
  },
  {
    src: "mysterybox",
  },
  {
    src: "materials",
  },
];*/

const Home = () => {
  return (
    <PageWithAds>
      <PageWithAds.Ads>
        <ChatBox />
        <BalloonGuild />
      </PageWithAds.Ads>
      <PageWithAds.Content>
        <NiyonSale />
        <Gallery>
          <GalleryImage src="founders" containerOffsetY="-2.5%" />
          <GalleryImage src="platform" />
          <GalleryImage src="mysterybox" />
          <GalleryImage src="materials" containerOffsetY="-5.5%" />
          {/*<GalleryPagingContainer>
            <PagingItem>&#60;&#60;</PagingItem>
            <PagingItem>&#60;</PagingItem>
            <PagingItem>1</PagingItem>
            <PagingItem>&#62;</PagingItem>
            <PagingItem>&#62;&#62;</PagingItem>
          </GalleryPagingContainer>*/}
        </Gallery>
        <AuctionHouse />
      </PageWithAds.Content>
    </PageWithAds>
  );
};

export default Home;
