import React from "react";
import {
  ChatAvatarContainer,
  ChatContainer,
  ChatContentContainer,
  Container,
  Content,
  EditButton,
  Header,
  SeeAll,
  Sender,
} from "./index.styles";

const ChatBox = () => {
  const chat = [1, 2, 3, 4, 1, 2, 3];

  return (
    <Container>
      <Header>
        <span>Public Chat</span>
        <EditButton>Edit</EditButton>
      </Header>
      {chat.map((p, i) => {
        return (
          <ChatContainer key={i}>
            <ChatContentContainer>
              <Sender>Sender</Sender>
              <Content>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
              </Content>
            </ChatContentContainer>
            <ChatAvatarContainer>
              <img src={`images/icons/alien_${p}.webp`} alt="icon" />
            </ChatAvatarContainer>
          </ChatContainer>
        );
      })}
      <SeeAll>See all</SeeAll>
    </Container>
  );
};

export default ChatBox;
