import React, { useState } from "react";
import {
  Container,
  CostContainer,
  IconCostContainer,
  CostSpan,
  MultiplierSpan,
  Content,
  ContentHeader,
  BidButton,
  AvatarContainer,
  Subtitle,
  StatsContainer,
  StatsItem,
  RemainingSpan,
} from "./index.styles";

const AuctionItem = () => {
  const [hovered, setHovered] = useState(false);

  return (
    <Container
      onMouseOver={() => setHovered(true)}
      onMouseOut={() => setHovered(false)}
    >
      <CostContainer hovered={hovered}>
        <IconCostContainer>
          <img src="images/logos/currency.webp" alt="logo" />
        </IconCostContainer>
        <CostSpan>250,000</CostSpan>
        <MultiplierSpan>&times; 3</MultiplierSpan>
      </CostContainer>
      <Content hovered={hovered}>
        <Subtitle>SPACESHIP</Subtitle>
        <ContentHeader>POLLEN</ContentHeader>
        <StatsContainer>
          <StatsItem>
            <span>BASE HP:</span>
            <span>12500</span>
          </StatsItem>
          <StatsItem>
            <span>BASE SHIELDS:</span>
            <span>14000</span>
          </StatsItem>
          <br />
          <StatsItem>
            <span>CORE POWER:</span>
            <span>94</span>
          </StatsItem>
          <br />
          <StatsItem>
            <span>WEAPON SLOTS:</span>
            <span>5</span>
          </StatsItem>
          <StatsItem>
            <span>UTILITY SLOTS:</span>
            <span>4</span>
          </StatsItem>
        </StatsContainer>
        <RemainingSpan>REMAINING: 3 DAYS 14 HRS 16 MINS</RemainingSpan>
        <BidButtonComp />
        <AvatarContainer>
          <img src="images/fairy_ship.webp" alt="logo" />
          <img src="images/stars_4.webp" alt="logo" />
        </AvatarContainer>
      </Content>
    </Container>
  );
};

const BidButtonComp = () => {
  const [hovered, setHovered] = useState(false);
  const [clicked, setClicked] = useState(false);

  return (
    <BidButton
      hovered={hovered}
      clicked={clicked}
      onMouseOver={() => setHovered(true)}
      onMouseOut={() => setHovered(false)}
      onMouseDown={() => setClicked(true)}
      onMouseUp={() => setClicked(false)}
    >
      BID
    </BidButton>
  );
};

export default AuctionItem;
